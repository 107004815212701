import React from 'react';
import styled from 'styled-components';
import { HoverUp, CardDropshadow } from '../../shared-styles/global.styles';
import { sendWebviewMessage } from '../../utils/webview';

const OuterContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 32px;
`;

const Text = styled.p`
  font-size: 24px;
  color: ${({ theme }) => theme.mainText};
  text-align: center;
  margin-bottom: 32px;
`;

const ToggleBtn = styled.a`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.mainText};
  display: flex;
  letter-spacing: 0.355556px;
  padding: 4px;
  height: 30px;
  align-items: center;
  justify-content: center;
  flex: 1;
  ${HoverUp}
  ${CardDropshadow}

  &:hover {
    cursor: pointer;
  }
`;

export default function StripeComplete() {
  const onClick = (e: any) => {
    e.preventDefault();
    sendWebviewMessage({ goToWallet: true });
  };

  return (
    <OuterContainer>
      <Container>
        <Text>
          Thank you for completing the wallet onboarding. Click below to go to your
          wallet.
        </Text>
        <ToggleBtn onClick={onClick}>Go To Wallet</ToggleBtn>
      </Container>
    </OuterContainer>
  );
}
