import { createGlobalStyle, css } from 'styled-components';

export const CARD_WIDTH = '100%';

export const GlobalStyle = createGlobalStyle<{ backgroundColor?: string }>`
    * {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif, Arial, Helvetica;
    }
    body,html {
        margin: 0;
        padding: 0;
        background-color: ${({ theme, backgroundColor }) =>
          backgroundColor ?? theme.darkBackground}
    }

    button {
        cursor: pointer;
        border-top: none;
        border: none;
    }

    @font-face {
        font-family: 'Times New Roman';
        src: local('Times New Roman'), url(assets/fonts/TimesNewRoman-Regular.ttf) format('truetype');
    }
    @font-face {
        font-family: 'Avenir';
        src: local('Avenir'), url(assets/fonts/Avenir-Regular.ttf) format('truetype');
    }
    @font-face {
        font-family: 'Helvetica';
        src: local('Helvetica'), url(assets/fonts/Helvetica-Regular.ttf) format('truetype');
    }
`;

export const CardDropshadow = css`
  box-shadow: 0px 3px 10px -4px rgba(0, 0, 0, 0.38);
`;

export const HoverUp = css`
  transform-origin: center center;

  @keyframes hover-up {
    25% {
      transform: translate(0%, -10%);
      transition: 0.5s ease-out;
    }
  }

  &:hover {
    animation-play-state: running;
    animation-name: hover-up;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
`;

export const BounceUp = css`
  display: inherit;
  transform-origin: center center;

  @keyframes shake-slow {
    25% {
      transform: translate(0px, -1px) rotate(-1deg);
    }
    75% {
      transform: translate(0px, -1px) rotate(1deg);
    }
  }

  &:hover {
    animation-play-state: running;
    animation-name: shake-slow;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }
`;

// export const SpinAnimation = css`
//     display: inherit;
//     transform-origin: center center;

//     @keyframes rotate {
//         100% {
//             transform: translate(0px, -100px) rotate(-180deg);
//         }
//         100% {
//             transform: translate(0px, 0px) rotate(-360deg);
//         }
//     }

//     &:hover {
//         animation-play-state: running;
//         animation-name: rotate;
//         animation-duration: 0.5s;
//         animation-timing-function: linear;
//         animation-iteration-count: 1;
//     }
// `;

// export const BounceUp = css`
//     .shake-slow {
//         display: inherit;
//         transform-origin: center center;
//     }

//     .shake-freeze,
//     .shake-constant.shake-constant--hover:hover,
//     .shake-trigger:hover .shake-constant.shake-constant--hover {
//         animation-play-state: paused;
//     }

//     .shake-freeze:hover,
//     .shake-trigger:hover .shake-freeze,
//     .shake-slow:hover,
//     .shake-trigger:hover .shake-slow {
//         animation-play-state: running;
//     }

//     @keyframes shake-slow {
//         25% {
//             transform: translate(0px, -1px) rotate(-1deg);
//         }
//         75% {
//             transform: translate(0px, -1px) rotate(1deg);
//         }
//     }

//     .shake-slow:hover,
//     .shake-trigger:hover .shake-slow,
//     .shake-slow.shake-freeze,
//     .shake-slow.shake-constant {
//         animation-name: shake-slow;
//         animation-duration: 0.5s;
//         animation-timing-function: linear;
//         animation-iteration-count: 1;
//     }
// `;
