import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import reduxThunk from 'redux-thunk';

import { DAppProvider } from '@usedapp/core';
import { App } from './components/App';
import { reducers } from './store/reducers/reducers';

const store = createStore(reducers, applyMiddleware(reduxThunk));
ReactDOM.render(
  <Provider store={store}>
    <DAppProvider config={{}}>
      <App />
    </DAppProvider>
  </Provider>,
  document.querySelector('#root')
);
