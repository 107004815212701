import React from 'react';
import { useTheme } from 'styled-components';
import { TailSpin } from 'react-loader-spinner';

export const Loader = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 2,
        backgroundColor: 'transparent',
      }}
    >
      <TailSpin
        color={theme.primary}
        wrapperStyle={{ backgroundColor: 'transparent' }}
        height={50}
        width={50}
      />
    </div>
  );
};
