import React from 'react';
import styled from 'styled-components';

import notFound from '../../assets/images/Success.png';

const NotFoundImg = styled.img`
    pointer-events: none;
    width: 37%;
    border-radius: 15px;
    background-color: #111344;
    padding-top: 10px;
    align-self: center;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex: 1;
`;

export default function Success(): JSX.Element {
    return <NotFoundImg src={notFound} />;
}
