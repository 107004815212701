import React, { lazy, Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Failure from '../components/empty-state/Failure';
import Success from '../components/empty-state/Success';
import { Loader } from '../components/Loader';
import StripeComplete from '../components/stripe-complete/StripeComplete';
import TreasuryView from '../components/stripe-complete/TreasuryView';

import QRCodes from '../utils/QRCodes.data';

const PortalCard = lazy(() => import('../components/profile-card/PortalCard'));
const NotFound = lazy(() => import('../components/not-found/NotFound'));

export function BaseRouter(): JSX.Element {
  const renderQRRoutes = () => {
    return Object.keys(QRCodes).map((key: string) => (
      <Route
        key={key}
        exact
        path={`/qr/${key}`}
        component={() => {
          window.location.replace(`${QRCodes[key]}`);
          return null;
        }}
      />
    ));
  };

  const reload = () => window.location.reload();

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path='/msg/success'
            render={() => {
              return <Success />;
            }}
          />
          <Route
            path='/msg/failure'
            render={() => {
              return <Failure />;
            }}
          />
          <Route
            path='/stripe/complete'
            render={() => {
              return <StripeComplete />;
            }}
          />
          <Route
            path='/treasury/view'
            render={() => {
              return <TreasuryView />;
            }}
          />
          {renderQRRoutes()}
          <Route
            path='/:username'
            render={() => {
              return <PortalCard />;
            }}
          />
          <Route
            path='/'
            render={() => {
              return <NotFound />;
            }}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
