import {
  AccountModel,
  GetProfileAction,
  ProfileActions,
  ProfileModel,
  SetIsLoadingAction,
  UserModel,
} from '../interfaces/profile.interface';
import { ProfileTypes } from '../types/profile.type';

const INIT_PROFILE_STATE: ProfileModel = {
  backgroundUrl: null,
  bannerUrl: null,
  createdAt: null,
  deleteAt: null,
  displayEmail: false,
  displayPhone: false,
  displayWebsite: false,
  donationsToggle: false,
  id: null,
  newsletterToggle: false,
  theme: 0,
  updatedAt: null,
  websiteUrl: null,
  darkMode: true,
};

const INIT_ACCOUNT_STATE: AccountModel = {
  businessType: null,
  createdAt: null,
  deleteAt: null,
  id: null,
  originalId: null,
  updatedAt: null,
};

export const INIT_STATE: UserModel = {
  id: null,
  bio: null,
  location: null,
  profilePhoto: null,

  firstName: null,
  lastName: null,

  email: null,
  phoneNumber: null,

  isVerified: false,
  username: null,

  Account: { account: INIT_ACCOUNT_STATE },
  AffiliateBrands: { affiliateBrands: [] },
  CustomLinks: [],
  Profile: { profile: INIT_PROFILE_STATE },
  ProfileCustomization: [],
  ServiceRequest: [],
  SocialPlatforms: [],
  Videos: [],
  WalletPlatforms: [],
  Orders: [],
  Products: [],
  Nfts: [],
  isLoading: true,
  ethereumWalletAddress: '',
  solanaWalletAddress: '',
  isEditMode: false,
};

// -----------------------------------------
// -- Balance Reducer --
// -----------------------------------------
const getBalance = (action: GetProfileAction): UserModel => {
  return action.payload;
};

const setIsLoading = (state: UserModel, action: SetIsLoadingAction): UserModel => {
  return { ...state, isLoading: action.payload };
};

/**
 * profileReducer represents the balance state within Redux.
 */
export const profileReducer = (
  state: UserModel = INIT_STATE,
  action: ProfileActions
): UserModel => {
  switch (action.type) {
    // Get User Data reducer
    case ProfileTypes.GET_PROFILE:
      return getBalance(action);
    case ProfileTypes.SET_IS_LOADING:
      return setIsLoading(state, action);

    default:
      return state;
  }
};
