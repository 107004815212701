interface Code<T> {
  [key: string]: T;
}

const QRCodes: Code<string> = {
  // Part 1 (Batch of 30)
  AE3X: 'https://paysplit.me/iqgray',
  B23A: 'https://paysplit.me/hyperart',
  T12A: 'https://paysplit.me/venicepsychiccenter',
  NH2B: 'https://paysplit.me/truartis',
  L24G: 'https://paysplit.me/alanprice123',
  Q154: 'https://paysplit.me/',
  L7Q3: 'https://paysplit.me/deltavera1',
  '4C2P': 'https://paysplit.me/venglish',
  AS3E: 'https://paysplit.me/bxgcountry',
  '1LY3': 'https://paysplit.me/anashart',
  ZF8H: 'https://paysplit.me/nickkennerly',
  POKP: 'https://paysplit.me/tarotcardreader',
  MAKB: 'https://paysplit.me/icecreamsongs',
  NLZ7: 'https://paysplit.me/',
  L1WX: 'https://paysplit.me/alanprice123',
  W68K: 'https://paysplit.me/icecreamsongs',
  SEJY: 'https://paysplit.me/venicepsychic407',
  TSCD: 'https://paysplit.me/miraclevibration',
  FV3F: 'https://paysplit.me/aberrio72',
  UROH: 'https://paysplit.me/tayross1990',
  XBMJ: 'https://paysplit.me/hunterventura',
  '6JRP': 'https://paysplit.me/',
  ZGUN: 'https://paysplit.me/tayross1990',
  '7VCT': 'https://paysplit.me/calypso1980',
  Q3YV: 'https://paysplit.me/juliarose',
  XH0N: 'https://paysplit.me/',
  ZUYE: 'https://paysplit.me/',
  '7HQZ': 'https://paysplit.me/calypso1980',
  R5AA: 'https://paysplit.me/',
  DMNX: 'https://paysplit.me/raregewel',
  XWPM: 'https://paysplit.me/joyfool',
  I8DM: 'https://paysplit.me/soulmang918',
  '0OEB': 'https://paysplit.me/brujaspa',
  AQVU: 'https://paysplit.me/soulmang918',
  '8ZQC': 'https://paysplit.me/gabriel_b_18',
  '0Q2W': 'https://paysplit.me/doughman81',
  YCJO: 'https://paysplit.me/nickkennerly',
  M815: 'https://paysplit.me/',
  AM5Q: 'https://paysplit.me/calypso1980',
  E01G: 'https://paysplit.me/',
  '0AGT': 'https://paysplit.me/doughman81',
  ESIY: 'https://paysplit.me/deedot',
  GFCN: 'https://paysplit.me/deedot',
  // Part 2 (Batch of 30)
  '2L86': 'https://paysplit.me/esmera5258',
  '10OC': 'https://paysplit.me/',
  '382S': 'https://paysplit.me/dcastroarts',
  RGYT: 'https://sosh.me/timo',
  '74BY': 'https://sosh.me/iamemxi',
  BQ87: 'https://paysplit.me/',
  ZQ9D: 'https://sosh.me/timo',
  TUEF: 'https://paysplit.me/',
  '4IU0': 'https://paysplit.me/tuhulu',
  WNO1: 'https://instagram.com/nikitamikkk',
  LKTD: 'https://paysplit.me/merlinxtremehenna',
  E0BZ: 'https://paysplit.me/jamesryan421',
  ZSW1: 'https://paysplit.me/pluc.usa',
  '5CIY': 'https://paysplit.me/venetianvibes',
  UK81: 'https://paysplit.me/',
  XVZ5: 'https://paysplit.me/iraaeee',
  Y9JE: 'https://paysplit.me/victor-tmg',
  K87H: 'https://sosh.me/eddypham',
  HAJV: 'https://paysplit.me/sosamoneyshop',
  '5CP5': 'https://paysplit.me/lionheart ',
  '8ITM': 'https://paysplit.me/pluc.usa',
  '9LRV': 'https://sosh.me/farkas',
  VXXG: 'https://paysplit.me/pluc.usa',
  CKS7: 'https://sosh.me/the_social_hacker',
  CB5F: 'https://paysplit.me/shoysauce',
  QTXZ: 'https://sosh.me/timo',
  BLPN: 'https://paysplit.me/shoysauce',
  XOJE: 'https://www.instagram.com/enoratrn',
  RSND: 'https://paysplit.me/',
  S33D: 'https://paysplit.me/',
  XVP9: 'https://paysplit.me/',
  // Part 3 (Batch of 23)
  TBLS: 'https://paysplit.me/',
  LF8M: 'https://paysplit.me/',
  NC1X: 'https://paysplit.me/',
  GL8J: 'https://paysplit.me/',
  CUQ5: 'https://paysplit.me/',
  VM0T: 'https://paysplit.me/',
  S1BI: 'https://paysplit.me/',
  V06D: 'https://paysplit.me/',
  '1KYN': 'https://paysplit.me/',
  '5Z8J': 'https://paysplit.me/',
  D4WZ: 'https://paysplit.me/',
  WEV2: 'https://paysplit.me/',
  CE4Z: 'https://paysplit.me/',
  '5S06': 'https://paysplit.me/',
  SS2H: 'https://paysplit.me/',
  GZLI: 'https://paysplit.me/',
  SF70: 'https://paysplit.me/',
  NYNE: 'https://paysplit.me/',
  J2R0: 'https://paysplit.me/',
  LBG3: 'https://paysplit.me/',
  IDAK: 'https://paysplit.me/',
  GSV2: 'https://paysplit.me/',
  A2I6: 'https://paysplit.me/',
  EIME: 'https://paysplit.me/',
  // VBTV (5 Signs)
  SCTL: 'https://paysplit.me/VBTV',
  '4H5F': 'https://paysplit.me/VBTV',
  C3XL: 'https://paysplit.me/VBTV',
  '4VXG': 'https://paysplit.me/VBTV',
  IHZT: 'https://paysplit.me/VBTV',
  // CUSTOM FOR PAYSPLIT TEAM
  '0000': 'https://paysplit.me/',
  '0001': 'https://paysplit.me/',
  '0002': 'https://paysplit.me/',
};

export default QRCodes;
