import { combineReducers } from 'redux';
import { UserModel } from '../interfaces/profile.interface';
import { profileReducer } from './profile.reducer';
/**
 * StoreState represents the entire Redux store within this app.
 */
export interface StoreState {
    profileReducer: UserModel;
    // userReducer: UserModel;
    // stripeReducer: StripeState;
    // accountReducer: AccountState;
    // balanceReducer: BalanceState;
}

/**
 * reducers contains all reducers within the Redux store.
 */
export const reducers = combineReducers<StoreState>({
    profileReducer,
    // userReducer,
    // stripeReducer,
    // accountReducer,
    // balanceReducer,
});
