import { DefaultTheme } from 'styled-components';

// export interface Theme {
//     white: string;
//     gray400: string;
//     gray500: string;
//     gray700: string;
//     gray800: string;
//     red: string;
//     blue: string;
//     lightBlue: string;
//     black: string;
// }

// // Theme colors.
// export const theme: DefaultTheme = {
//     white: '#fff',
//     gray400: '#bababa',
//     gray500: '#8C8A9A',
//     gray700: '#818181',
//     gray800: '#414042',
//     red: '#E14161',
//     blue: '#0075FF',
//     lightBlue: '#1BBFF1',
//     black: '#001B2E',
// };

// PaySplit light theme colors.
export const theme: DefaultTheme = {
  whiteAlways: '#FFFFFF',
  blackAlways: '#000',
  darkBackground: '#1A2633',
  alternateBackground: '#202D3C',
  background: '#1A2633',
  container: '#233241',
  mainGray: '#5B6F85',
  mediumGray: '#BABABA',
  lightGray: '#6A829E',
  primary: '#E86E8A', // pink
  secondary: '#478898',
  mainText: '#FFFFFF',
  shadow: '#000',
  red: '#ff5151',
  font: 'Avenir',
};

// PaySplit dark theme colors.
export const darkTheme: DefaultTheme = {
  whiteAlways: '#FFFFFF',
  blackAlways: '#000',
  darkBackground: '#1A2633',
  alternateBackground: '#202D3C',
  background: '#1A2633',
  container: '#233241',
  mainGray: '#5B6F85',
  mediumGray: '#BABABA',
  lightGray: '#6A829E',
  primary: '#E86E8A', // pink
  secondary: '#478898',
  mainText: '#FFFFFF',
  shadow: '#000',
  red: '#ff5151',
  font: 'Avenir',
};

// // PaySplit light theme colors.
// export const SpecialTheme: DefaultTheme = {
//   whiteAlways: '#fff',
//   blackAlways: '#000',
//   darkBackground: '#001B2E',
//   alternateBackground: '#202D3C',
//   background: '#E14161',
//   container: '#fff',
//   mainGray: '#BABABA',
//   mediumGray: '#BABABA',
//   lightGray: 'transparent',
//   primary: '#ff5151',
//   secondary: '#38C2B7',
//   mainText: '#000',
//   shadow: '#19BFF1',
//   red: '#ff5151',
//   font: 'Avenir',
// };

// Responsive screen sizes for devices.
const size = {
  mobileXS: '320px',
  mobileS: '414px',
  mobileL: '590px',
  tabletS: '730px',
  tabletM: '786px',
  tabletL: '960px',
  laptopS: '1172px',
  laptopM: '1442px',
};

// Media queries.
export const device = {
  mobileXS: `(max-width: ${size.mobileXS})`,
  mobileS: `(max-width: ${size.mobileS})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tabletM: `(max-width: ${size.tabletM})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptopM: `(max-width: ${size.laptopM})`,
};
